'use client';

import { FC } from 'react';
import Link from './Link';

export interface PricingCustomerStoriesProps {
  hideComponent?: boolean;
  title: string;
  subtitle: string;
  description: string;
  cta: {
    href: string;
    title: string;
  };

  gridItems: {
    image: React.ReactNode;
    title: string;
    description: string;
    href: string;
  }[];
}

const PricingCustomerStories: FC<PricingCustomerStoriesProps> = ({
  hideComponent,
  title,
  subtitle,
  description,
  cta,
  gridItems = [],
}) => {
  const header = (
    <div className="flex flex-col gap-3 text-center">
      <p className="text-14/14 uppercase tracking-wide">{subtitle}</p>
      <h3 className="text-30/35 md:text-56/65 font-500 tracking-tight">{title}</h3>
      <p className="text-17/24 md:text-20/28">{description}</p>
      <div className="flex justify-center items-center">
        <Link className="block button button-size-medium button-secondary-gray" href={cta.href}>
          {cta.title}
        </Link>
      </div>
    </div>
  );

  const storiesGrid = (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-gutter-mobile md:gap-x-gutter-desktop gap-y-3">
      {gridItems.map((item) => (
        <div
          key={`pricing-customer-story-${item.href}`}
          className="h-full px-4 py-6 bg-gray-100 rounded-20 flex flex-col gap-6 justify-center items-center relative hover:bg-citron-100 transition-colors duration-200 ease-in-out-quad"
        >
          {item.image}
          <div className="flex flex-col gap-2 text-center items-center justify-center">
            <p className="text-84/84 font-500 tracking-tight">
              <Link href={item.href} className="link-cover">
                {item.title}
              </Link>
            </p>
            <p className="text-20/28">{item.description}</p>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className={`${hideComponent ? "hidden" : "flex"} my-8 md:my-22 px-container-mobile md:px-container-desktop`}>
      <div className="mx-auto max-w-grid">
        <div className="flex flex-col gap-6">
          {header}
          {storiesGrid}
        </div>
      </div>
    </div>
  );
};

export default PricingCustomerStories;
